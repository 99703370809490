<script>
import unassignment_confirmation_mixin from '@/shared/confirmation_for_unassignment_mixin'
import ListOfCleaners from './list_of_cleaners.vue'
export default {
  mixins: [ unassignment_confirmation_mixin ],
  components: { ListOfCleaners },
  data() {
    return {
      rejectedUsers: null
    }
  },
  props: {
    job: {
      type: Object
    },
    domId: {
      type: String,
      required: true
    },
    forCleaner: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    address() {
      return this.job.full_address
    },
    assignable() {
      return this.job.assignable
    },
    calledOffCleaners() {
      return this.job.called_off_cleaners
    },
    unassignable() {
      return this.job.unassignable
    },
    client() {
      return this.job.client_name
    },
    cleaningJobId() {
      return this.job.cleaning_job_id
    },
    css() {
      return this.job.status_color
    },
    hasJob() {
      return this.job ? true : false
    },
    jobId() {
      return 'Job ID #' + this.job.id
    },
    phone() {
      return 'Phone Number: ' + this.job.client_phone
    },
    title() {
      return this.job.pretty_requested_cleaning
    },
    durationAsHours() {
      return this.job.duration_as_hours
    },
    status() {
      if (this.job.on_my_way) {
        if (this.job.on_my_way_info) {
          let info = "On My Way!"
          if (this.job.on_my_way_info.eta) {
            info += "<br>ETA" + this.job.on_my_way_info.eta
          }
          if (this.job.on_my_way_info.distance) {
            info += "<br>" + this.job.on_my_way_info.distance
          }
        }
        return 'On My Way!'
      }
      return this.job.job_status
    },
    jobStatusCss() {
      return 'text-' + this.job.job_status_color
    },
    paymentStatus() {
      return this.job.payment_status
    },
    paymentStatusCss() {
      return 'text-' + this.job.payment_status_color
    },
    billableStatus() {
      return this.job.billable_status
    },
    billableStatusCss() {
      return 'text-' + this.job.billable_status_color
    },
    cardStatus() {
      return this.job.card_status
    },
    cardStatusCss() {
      return 'p-2 text-light bg-' + this.job.card_status_color
    },
    accepted() {
      return this.job.accepted
    },
  },
  mounted() {
    this.getRejectedBy()
  },
  methods: {
    getRejectedBy() {
      if ( (this.rejectedUsers != null) || (this.job.pending_visit) ) {
        return
      }
      this.$http.get('/api/reservations/' + this.job.id + '/rejected_by').then(response => {
        if (response.data.length == 0) {
          this.rejectedUsers = []
        } else {
          this.rejectedUsers = response.data
        }
      })
    },
    removeRejectedUser(cleaner) {
      this.$parent.$emit('unassign-cleaner', { jobId: cleaner.job_id, notify: false })
    },
    unassign() {
      this.confirmUnassignment((args) => {
        this.$emit('unassign-cleaner', { jobId: this.cleaningJobId, ...args })
      })
    }
  }
}
</script>
