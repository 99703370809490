<template>
  <div v-if="show" class="visit-card" :class="'w-'+durationAsHours">
    <div class="col-desc" :class="css">
      <h1 class="col-desc-title" :class="css+'-text'">{{title}}</h1>
      <ul class="col-desc-description">
        <li v-b-toggle="'area-' + domId" class="blue-text">{{jobId}}&nbsp;
          <i class="float-right fal fa-chevron-down pt-1"></i>
        </li>
        <li v-html="client"></li>
        <b-collapse :id="'area-'+domId">
          <li>{{address}}</li>
          <li>{{phone}}</li>
        </b-collapse>
      </ul>
      <list-of-cleaners :cleaners="rejectedUsers" @click="removeRejectedUser($event)" :dom-id="domId" prefix="Rejected by" />
      <list-of-cleaners :cleaners="calledOffCleaners" :dom-id="domId" prefix="Called off by" />
      <a class="col-desc-link" @click.prevent="$emit('event-click', job.id)">Visit details <i class="float-right fal fa-plus"></i></a>
      <h6 class="mt-2 text-center" :class="paymentStatusCss">{{paymentStatus}}</h6>
      <h6 class="mt-2 text-center" :class="billableStatusCss">{{billableStatus}}</h6>
      <h6 class="mt-2 text-center" :class="cardStatusCss">{{cardStatus}}</h6>
      <button type="button" class="btn btn-mini btn-green mx-0 font-12" @click="$emit('assign-cleaner', cleaningJobId)">Assign Cleaner</button>
    </div>
  </div>
</template>
<script>
import BaseJobCell from './base_job_cell.vue'
export default {
  extends: BaseJobCell,
  computed: {
  }
}
</script>
