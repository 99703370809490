<template>
  <div>
    <base-modal classes="background-soft-gray" modal-size="" dom-id="assignmentModal" :show="show" title="Incomplete jobs" @close="$emit('close')">
      <div slot="content">
        <div class="alert alert-danger" role="alert">
          <h6 class="alert-heading">The following cleaners haven't completed their visits today:</h6>
          <ul class="list-group list-group-flush">
            <li v-for="item in info" class="list-group-item list-group-item-danger">
              {{item.name}}
              {{item.jobs}} jobs
            </li>
          </ul>
          <h6 class="mb-0 mt-2 alert-heading">Please complete visits today to avoid report errors</h6>
        </div>
      </div>
      <div slot="footer">
        <div class="text-center mx-auto">
          <button type="button" class="btn btn-default btn-primary" data-dismiss="modal" aria-label="Close">Close</button>
        </div>
      </div>
    </base-modal>
  </div>
</template>
<script>
import api from '@/clients/api'
import BaseModal from '@/modals/base_modal.vue'

export default {
  props: {
    info: {
      type: Array
    },
    show: {
      type: Boolean,
      require: true
    }
  },
  components: {
    BaseModal
  },
  methods: {},
}
</script>
