<template>
  <div class="cal-table-col col-popup" :class="className">
    <weekly-event v-for="event in events" :event="event" :key="event.id"></weekly-event>
  </div>
</template>
<script>
  import WeeklyEvent from './weekly_event.vue'

  export default {
    components: { WeeklyEvent },
    props: [ 'dayData', 'forCss' ],
    computed: {
      ad() {
        return this.dayData
      },
      className() {
        return ((this.forCss.hour24 < 8) || (this.forCss.hour24 > 17) || (this.forCss.idx > 4)) ? 'cal-time-off' : '';
      },
      events() {
        return this.dayData.eventData ? this.dayData.eventData : [];
      }
    }
  }
</script>
