<template>
  <div :id="domId" class="cal-time-nt" :class="event.color">
    {{title}}
    <event-popup :event="event" :dom-id="domId"></event-popup>
  </div>
</template>
<script>
import EventPopup from './EventPopup.vue'
export default {
  components: { EventPopup },
  data() {
    return { user: Window.user }
  },
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  computed: {
    css() {
      var cls = this.event.status_color
      if (this.event.manual) {
        cls = cls + ' manual-event'
      }
      return cls
    },
    domId() {
      return "weekly_" + event.domId;
    },
    title() {
      var title = ''
      if (this.user == 'Client') {
        title = this.event.pretty_requested_cleaning +  ' - '  + this.event.duration_time
      } else {
        title = this.event.pretty_requested_cleaning + ' - ' + this.event.duration_time + ' - ' + this.event.client_name
      }
      return title
    }
  }
}
</script>
