<template>
  <div v-if="notFiltered">
    <span v-if="isClient" class="col-desc" :class="css" @click="$emit('event-click', event.id)">{{title}}</span>
    <span v-else class="col-desc" :class="css">
      <spinner :show="loading" :use-modal="false" message="Saving"></spinner>
      <i v-if="!loading" @click="$emit('event-click', event.id)">{{title}}</i>
      <i v-if="!loading && hasJob" class="fal check-mark-visit" :class="jobCompleted ? 'fa-check-square' : 'fa-square'"></i>
      <list-of-cleaners :cleaners="calledOffCleaners" :dom-id="domId" prefix="Called off by" />
    </span>
  </div>
</template>
<script>
import api from '@/clients/api'
import Spinner from '@/shared/spinner.vue'
import ListOfCleaners from '@/grid/list_of_cleaners.vue'
export default {
  props: {
    event: {
      type: Object,
      required: true
    }
  },
  components: { Spinner, ListOfCleaners },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    filters() {
      return this.$parent.$parent.eventFilters
    },
    notFiltered() {
      if (this.filters?.job) {
        if (this.jobStatus[this.filters.job] === undefined) {
          console.error("Filter method [", this.filters.job, "] is undefined")
          return true
        }
        return this.jobStatus[this.filters.job]
      }
      return true
    },
    domId() {
      return "visit_" + this.event.id
    },
    css() {
      var cls = this.event.status_color
      if (this.event.manual) {
        cls = cls + ' manual-event'
      }
      return cls
    },
    calledOffCleaners() {
      return this.event.called_off_cleaners
    },
    jobStatus() {
      return this.event.job_status
    },
    hasJob() {
      return this.event.has_job
    },
    jobCompleted() {
      return this.event.job_completed
    },
    title() {
      var title = this.event.pretty_requested_cleaning + ' - ' + this.event.duration_time + ' - '
      if (this.isClient) {
        title += this.event.full_address
      } else {
        title += this.event.client_name
      }
      return title
    }
  }
}
</script>
