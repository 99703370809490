<template>
  <div v-show="smartShow">
    <b-collapse id="collapse-unavailables" v-if="!available">
      <div class="grid-row">
        <div v-if="title" class="row-item title" :class="available ? 'user-available': 'user-unavailable'">
          <span class="font-weight-bold">
            <template v-if="titleUrl"><a :href="titleUrl" v-html="title"></a></template>
            <template v-else><span v-html="title"></span></template>
          </span>
        </div>
        <template v-if="forStaff">
          <jobs-in-cell
            @assign-cleaner="$emit('assign-cleaner', $event)"
            @unassign-cleaner="$emit('unassign-cleaner', $event)"
            @event-click="$emit('event-click', $event)"
            v-for="(jobs, idx) in jobsForHours"
            :jobs="jobs"
            :key="'job_'+idx"
            :index-hour="hours[idx]"
            :available-hours="availableHours"
          ></jobs-in-cell>
        </template>
        <template v-else>
          <jobs-in-cell
            @complete="$emit('complete', $event)"
            v-for="(jobs, idx) in jobsForHours"
            :jobs="jobs"
            :key="'sj_'+idx"
            :for-cleaner="true"
          ></jobs-in-cell>
        </template>
      </div>
    </b-collapse>
    <div v-else>
      <div class="grid-row position-relative">
        <div v-if="title" class="row-item" :class="available ? 'user-available': 'user-unavailable'">
          <div class="mx-auto my-auto px-1 py-2">
            <div class="user-grid-work">
              <div v-if="avatarUrl" class="user-grid-thumbnail display-block">
                <img :src="avatarUrl" class="my-2 mx-auto user-avatar" width="50px" height="50px" />
              </div>
              <div class="user-grid-work__info-area">
                <div class="user-grid-info">
                  <div class="font-weight-bold text-center pt-1">
                    <template v-if="titleUrl">
                      <a :href="titleUrl" v-html="title"></a>
                    </template>
                    <template v-else><span v-html="title"></span></template>
                  </div>
                  <div class="rating">
                    <div class="rating-item">
                      <span>Cleaning</span>
                      <div class="mx-auto py-0 px-0">
                        <stars :count="rating" :inline="true" :size="15" rounded-corners class="rating-center"></stars>
                      </div>
                    </div>
                    <div class="rating-item">
                      <span>Arrivals / Calloffs</span>
                      <div class="mx-auto py-0 px-0">
                        <stars :count="calloffRating" :inline="true" :size="15" rounded-corners class="rating-center"></stars>
                      </div>
                    </div>
                  </div>
                  <hr class="my-1" />
                </div>
                <p class="jobs-info">{{ jobsCompleted }}</p>
                <p v-if="lastWorkingAgo" class="jobs-info">{{ lastWorkingAgo }}</p>
                <a v-if="titleUrl" class="btn btn-block btn-green white-text" @click.prevent="$emit('event-plus-click')">Send Daysheet</a>
              </div>
            </div>
          </div>
          <!-- END -->
          <div v-if="cantClaimJobs" class="height-50">
            <div class="alert alert-danger mb-0 claim-suspended" role="alert">
              Ability to claim suspended due to call offs. Visit the cleaner's account to reset.
              <span v-if="reviewRequestedAt">Account review requested at {{ reviewRequestedAt }}</span>
            </div>
          </div>
        </div>
        <template v-if="forStaff">
          <jobs-in-cell
            @assign-cleaner="$emit('assign-cleaner', $event)"
            @unassign-cleaner="$emit('unassign-cleaner', $event)"
            @event-click="$emit('event-click', $event)"
            v-for="(jobs, idx) in jobsForHours"
            :jobs="jobs"
            :key="'job_'+idx"
            :index-hour="hours[idx]"
            :available-hours="availableHours"
          ></jobs-in-cell>
        </template>
        <template v-else>
          <jobs-in-cell
            @complete="$emit('complete', $event)"
            v-for="(jobs, idx) in jobsForHours"
            :jobs="jobs"
            :key="'sj'+idx"
            :for-cleaner="true"
          ></jobs-in-cell>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import JobsInCell from './jobs_in_cell.vue'
import TimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import Stars from '@/shared/stars.vue'
TimeAgo.addLocale(en)
export default {
  data() {
      return {}
    },
  components: {
    JobsInCell, Stars
  },
  props: {
    forStaff: {
      type: Boolean,
      default: true
    },
    hours: {
      type: Array,
      required: true
    },
    jobsPerHour: {
      type: Object
    },
    show: {
      type: Boolean,
      default: true
    },
    hasFilters: {
      type: Boolean,
      default: false
    },
    team: {
      type: Object,
      required: true
    }
  },
  computed: {
    availableHours() {
      return this.team.available_hours
    },
    avatarUrl() {
      return this.team.avatar_url
    },
    cantClaimJobs() {
      return this.team.cant_claim_jobs
    },
    lastWorkingAgo() {
      if (this.team.last_working_time) {
        const timeAgo = new TimeAgo('en-US')
        return 'Worked ' + timeAgo.format(new Date(this.team.last_working_time))
      }
    },
    jobsCompleted() {
      return this.team.jobs_completed + ' jobs in the last 4 weeks'
    },
    jobsForHours() {
      var jobs = []
      for (var i = 0; i < this.hours.length;) {
        var maxDur = 1
        jobs[i] = []
        var hour = this.hours[i]
        var hourlyJobs = null
        if (this.jobsPerHour) {
          hourlyJobs = this.jobsPerHour[hour.hour24]
          if (hourlyJobs) {
            for (var j = 0; j < hourlyJobs.length; j++) {
              const job = hourlyJobs[j]
              if (job) {
                if (maxDur < job.duration_as_hours) {
                  maxDur = job.duration_as_hours
                }
                jobs[i].push(job)
              }
            }
          }
        }
        i += maxDur
      }
      return jobs
    },
    title() {
      return this.team.name
    },
    titleUrl() {
      return '/admin/cleaners/' + this.team.id
    },
    reviewRequestedAt() {
      return this.team.review_requested_at
    },
    smartShow() {
      var hasItems = false
      if (this.hasFilters) {
        if (this.jobsPerHour == null) {
          return false
        }
        for (const [key, value] of Object.entries(this.jobsPerHour)) {
          hasItems = hasItems || (value.length > 0)
        }
        return hasItems && this.show
      }
      return this.show
    },
    available() {
      return this.team.available
    },
    rating() {
      return this.team.rating * 1
    },
    calloffInfo() {
      return 'Total callofs: ' + this.calledOffJobs.total + ', completed jobs: ' + this.calledOffJobs.completed_jobs
    },
    calledOffJobs() {
      return this.team.called_off_jobs
    },
    calloffRating() {
      return 5 - (this.calledOffJobs.total / this.calledOffJobs.completed_jobs * 5)
    },
  },
}
</script>
