<template>
  <div class="alert alert-primary" role="alert">
    <spinner :show="loading" message="Loading"></spinner>
    <b-form-group v-if="!loading" label="Enabled Areas">
      <b-form-checkbox-group
        v-model="selection"
        :options="options"
        name="area-ids"
        @change="onChange"
        switches
      />
    </b-form-group>
  </div>
</template>
<script>
import api from '@/admins/api'
import Spinner from '@/shared/non_modal_spinner.vue'
export default {
  inheritAttrs: false,
  components: { Spinner },
  data() {
    return {
      areas: [],
      loading: false,
      selection: []
    }
  },
  computed: {
    options() {
      var options = []

      Array.prototype.forEach.call(this.areas, function(a) {
        if (typeof(a.id) != 'object') {
          options.push({
            value: a.id.toString(),
            text: a.name
          })
        }
      })
      return options
    }
  },
  methods: {
    onChange(ids) {
      this.loading = true
      api.updateAreasSettings(ids).then(() => {
        //this.loading = false
        this.$emit('update')
      })
    }
  },
  mounted() {
    this.loading = true
    api.getAllAreas().then(response => {
      this.areas = response.data
      api.getAreasSettings().then(response => {
        this.selection = response.data.value
        this.loading = false
      })
    })
  }
}
</script>
