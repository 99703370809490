<template>
  <b-popover v-if="domId" :target="domId">
    <div class="popup-wrapper">
      <div class="popup-header"><button type="button" class="close" @click="$root.$emit('bv::hide::popover')">×</button><span v-html="title"></span></div>
      <div class="popup-body">
        <div class="row" v-for="row in event.infoRows">
          <!-- TODO: If the change of classes is correct erase these lines -->
          <!-- <label class="col-form-label" :class="[ row.short ? 'col-6' : 'col-sm-6' ]" v-html="row.text"></label> -->
          <!-- <label class="col-form-label font-mid-bold" :class="[ row.short ? 'col-6' : 'col-sm-6' ]">{{row.detail}}</label> -->
          <!-- End TODO -->
          <label class="col-form-label col-sm-12 col-md-6" v-html="row.text"></label>
          <label class="col-form-label font-mid-bold col-sm-12 col-md-6">{{row.detail}}</label>
        </div>
        <div v-if="event.extras" class="row col-extra-pop">
           <label class="col-sm-6 col-form-label ">
              Extras Added <span class="green-box ml-2 js-tooltip"><i class="fal fa-chevron-down"></i></span>
           </label>
           <label class="col-sm-6 col-form-label font-mid-bold">{{event.totalExtraCharges}}</label>
           <div class="extra-sec" v-show="showExtras" v-html="event.extras"></div>
        </div>
        <div class="btn-set popup-btn-group">
          <a class="btn btn-default btn-blue" v-bind:href="'/properties/'+event.locationId">View Property</a>
          <a class="btn btn-default btn-red" v-show="isAdmin" v-bind:href="'/bookings/'+event.id+'/edit'">Edit Booking</a>
          <button v-show="editable(event)" class="btn btn-default btn-blue" @click="reschedule(event)">Reschedule</button>
          <button v-show="isAdmin" class="btn btn-default btn-red" @click="cancel(event)">Cancel</button>
        </div>
      </div>
      <div class="map-icon-btm" @click="showMap = !showMap"><img src="@images/map-icon.png"></div>
      <div class="map-sec" id="map" v-show="showMap"><button type="button" class="close" @click="showMap = false">×</button><img v-bind:src="event.mapImgSrc"></img></div>
    </div>
  </b-popover>
</template>
<script>
  export default {
    data () {
      return {
        showExtras: false,
        showMap: false
      }
    },
    props: [ 'event', 'domId' ],
    mounted() {
      var self = this;
      $('.js-tooltip').on({
        mouseenter: function () {
          self.showExtras = true;
        },
        mouseleave: function () {
          self.showExtras = false;
        }
      });
      if (this.event && this.event.data && this.event.data.schedule) {
        this.frequency = this.event.data.schedule.frequency
      }
    },
    computed: {
      extrasDomId() {
        return 'extras_' + this.domId;
      },
      target() {
        return this.$el;
      },
      schedule() {
        return this.event.schedule
      },
      title() {
        var data = this.event
        var title = data.title
        if (data.clientId && data.clientName) {
          title += ' - <a href="/admin/clients/' + data.clientId + '">' + data.clientName + '</a>'
        }
        return title
      }
    },
    methods: {
      cancel(calcEvent) {
        var self = this;
        this.$dialog.confirm('Are you sure you want to cancel?', { okText: 'Confirm', cancelText: 'No',})
          .then(function () {
            var appId = calcEvent.id;
            var when = calcEvent.when;
            self.$http.delete('/reservations/' + appId, { params: { when: when } }).then(response => {
              self.$root.$emit('reloadEvents');
              self.$root.$emit('bv::hide::popover');
            });
          });
      },
      editable(calcEvent) {
        const isInTheFuture = Date.compare(Date.today(), Date.parse(this.event.start)) == -1

        return isInTheFuture && calcEvent.schedule != null
      },

      onFrequencyChange(newFrequency) {
        this.frequency = newFrequency
      },
      reschedule(calcEvent) {
        this.$parent.$emit('reschedule', calcEvent)
      }
    }
}
</script>
