<template>
  <div>
    <div class="row">
      <div class="col">
        <h1 class="main-head">Jobs Assigned</h1>
      </div>
    </div>
    <div class="row mb-2">
      <div class="col-sm-6 col-md-3">
        <button class="btn btn-default btn-block btn-green" @click="$emit('show-daysheets')">Day Sheets</button>
      </div>
      <div class="col-sm-6 col-md-3 mt-3">
        <job-status-filter @update="jobStatusFilter = $event" />
      </div>
      <div class="col-sm-4 col-md-3 mt-3">
        <input v-model="cleanerFilter" class="form-control" placeholder="Cleaner's Name" />
      </div>
      <div class="col-sm-4 col-md-3 mt-3">
        <input v-model="clientFilter" class="form-control" placeholder="Client's Name" />
      </div>
      <div class="col-sm-4 col-md-6 mx-md-auto mt-3">
        <input v-model="addressFilter" class="form-control" placeholder="Address" />
      </div>
    </div>
    <div class="clearfix sticky">
      <div class="float-left">
        <button v-if="hasPendingRequests" @click="$emit('show-requests', 'pending')" type="button" class="btn btn-warning">
          <span class="badge badge-light">{{ pendingRequests.length }}</span> Pending Requests
        </button>
        <button v-if="hasUnservedRequests" @click="$emit('show-requests', 'unserved')" type="button" class="btn btn-warning">
          <span class="badge badge-light">{{ unservedRequests.length }}</span> Unserved Requests
        </button>
        <button v-if="hasFlexibleRequests" @click="$emit('show-requests', 'flexible')" type="button" class="btn btn-warning">
          <span class="badge badge-light">{{ flexibleRequests.length }}</span> Flexible Requests
        </button>
        <button v-if="hasChangeRequests" @click="$emit('show-requests', 'change')" type="button" class="btn btn-warning">
          <span class="badge badge-light">{{ changeRequests.length }}</span> Change Requests
        </button>
      </div>
      <div class="float-right">
        <h2 class="font-weight-light text-nowrap">{{ title }}</h2>
      </div>
    </div>
    <div class="grid-container">
      <div class="grid-table">
        <div class="grid-header">
          <div class="grid-row">
            <span>
              {{ teamsLoadedCount }} / {{ teamsTotal }}
              {{ visitsLoadedCount }} / {{ visitsTotal }}
              <i v-if="stillLoading" class="fal fa-sync-alt fa-spin"></i>
            </span>
            <span v-for="hour in hours" :key="'header_hour_'+hour.hour24" class="grid-col font-weight-bold">{{hour.label}}</span>
          </div>
        </div>
        <div class="grid-body">
          <template v-for="team in teams">
          <jobs-in-table-row
            v-if="team"
            :dbg="team.id"
            @assign-cleaner="$emit('assign:cleaner', $event)"
            @unassign-cleaner="$emit('unassign-cleaner', $event)"
            @event-click="$emit('event-click', $event)"
            @event-plus-click="$emit('send:daysheet-text', team.id)"
            :key="'team_'+team.id"
            :hours="hours"
            :jobs-per-hour="filteredJobsPerTeam(team)"
            :has-filters="hasFilters"
            :team="team"
          ></jobs-in-table-row>
          <span v-else>Loading <i class="fal fa-sync-alt fa-spin"></i></span>
          </template>
        </div>
        <div class="grid-footer">
          <div class="grid-row">
            <span>/</span>
            <span v-for="hour in hours" :key="'hour_'+hour.hour24" class="grid-col font-weight-bold">{{hour.label}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="text-center">
      <button class="btn btn-default btn-primary btn-mini" v-b-toggle="'collapse-unavailables'">Show unavailables cleaners</button>
    </div>
    <div class="mt-4">
      <h1 class="main-head">Unassigned Jobs</h1>
    </div>
    <div v-for="hour in hours" :key="'hour_'+hour.hour24">
      <div v-if="hourJobs(hour).length != 0">
        <h1 class="main-head">{{ hour.label }}</h1>
        <div class="grid-table">
          <div class="row-visits d-flex overflow-auto">
            <jobs-in-cell
              @assign-cleaner="$emit('assign:cleaner', $event)"
              @unassign-cleaner="unassign"
              @event-click="$emit('event-click', $event)"
              v-for="(jobs, idx) in hourJobs(hour)"
              :jobs="jobs"
              :key="'jobs_'+idx"
              :for-unassigned="true"
            ></jobs-in-cell>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue"
import vuedraggable from "vuedraggable"
import JobsInTableRow from "./jobs_in_table_row.vue"
import JobStatusFilter from "@/shared/job_status_filter.vue"
import AssignmentModal from "./assignment_modal.vue"
import {
  dateToAppFormat
} from "@/utils"

import JobsInCell from "./jobs_in_cell.vue"

export default {
  name: 'GridAsWidget',
  components: {
    vuedraggable,
    JobsInTableRow,
    AssignmentModal,
    JobsInCell,
    JobStatusFilter
  },
  props: {
    hours: {
      type: Array,
      required: true
    },
    teams: {
      type: Array,
      required: true
    },
    jobsPerTeam: {
      type: Object,
      required: true
    },
    unassignedJobs: {
      type: Object,
      required: true
    },
    requests: {
      type: Object,
      required: true
    },
    title: {
      type: String
    },
    counts: {
      type: Object
    },
    stillLoading: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      cleanerFilter: '',
      clientFilter: '',
      addressFilter: '',
      jobStatusFilter: ''
    }
  },
  computed: {
    teamsTotal() {
      if (this.counts && this.counts.teams) {
        return this.counts.teams.total
      }
    },
    teamsLoadedCount() {
      if (this.counts && this.counts.teams) {
        return this.counts.teams.loaded
      }
    },
    visitsTotal() {
      if (this.counts && this.counts.visits) {
        return this.counts.visits.total
      }
    },
    visitsLoadedCount() {
      if (this.counts && this.counts.visits) {
        return this.counts.visits.loaded
      }
    },
    filters() {
      return {
        cleaner: this.cleanerFilter,
        client: this.clientFilter,
        address: this.addressFilter,
        status: this.jobStatusFilter
      }
    },
    total() {
      return "Total: " + this.numberOfReservations
    },
    unassignedJobsList() {
      return this.unassignedJobs
    },
    hasFilters() {
      return (this.cleanerFilter.length > 2) || (this.clientFilter.length > 2) || (this.addressFilter.length > 2) || (this.jobStatusFilter != '')
    },
    hasChangeRequests() {
      if (this.changeRequests) {
        return this.changeRequests.length > 0
      }
    },
    hasFlexibleRequests() {
      if (this.flexibleRequests) {
        return this.flexibleRequests.length > 0
      }
    },
    hasPendingRequests() {
      if (this.pendingRequests) {
        return this.pendingRequests.length > 0
      }
    },
    hasUnservedRequests() {
      if (this.unservedRequests) {
        return this.unservedRequests.length > 0
      }
    },
    changeRequests() {
      return this.requests.change
    },
    flexibleRequests() {
      return this.requests.flexible
    },
    pendingRequests() {
      return this.requests.pending
    },
    unservedRequests() {
      return this.requests.unserved
    }
  },
  methods: {
    filteredJobsPerTeam(team) {
      var jobs = {}
      const teamJobs = this.jobsPerTeam[team.id]
      if (this.hasFilters && teamJobs) {
        var numResult = 0
        for (const [key, value] of Object.entries(teamJobs)) {
          const jobsForHour = value
          const allowedJobsForHour = jobsForHour.filter(j => {
            const ok = this.allowedJobByFilter(j)
            if (ok) {
              numResult += 1
            }
            return ok
          })
          if (numResult > 0) {
            jobs[key] = allowedJobsForHour
          }
        }
        return jobs
      } else {
        return this.jobsPerTeam[team.id]
      }
    },
    onReservationUpdated(e) {
      this.jobIdToAssign = null
      this.loadData()
    },
    unassign(args) {
      this.$emit('unassign-cleaner', args)
    },
    hourJobs(hour) {
      var jobs = []
      var maxDur = 1
      var hourlyJobs = null
      if (this.unassignedJobs) {
        hourlyJobs = this.unassignedJobs[hour.hour24]
        if (hourlyJobs) {
          for (var i = 0; i < hourlyJobs.length; i++) {
            jobs[i] = []
            const job = hourlyJobs[i]
            if (job && this.allowedJobByFilter(job)) {
              if (maxDur < job.duration_as_hours) {
                maxDur = job.duration_as_hours
              }
              jobs[i].push(job)
            }
          }
        }
      }
      return jobs
    },
    allowedJobByFilter(job) {
      if (job) {
        if (this.hasFilters) {
          var allowed = true
          if (this.filters.cleaner && job.assigned_to) {
            const txt = this.filters.cleaner.trim().toLowerCase()
            if (txt.length > 2) {
              allowed = allowed && job.assigned_to.toLowerCase().includes(txt)
            }
          }
          if (this.filters.client) {
            const txt = this.filters.client.trim().toLowerCase()
            if (txt.length > 2) {
              allowed = allowed && job.client_name.toLowerCase().includes(txt)
            }
          }
          if (this.filters.address) {
            const txt = this.filters.address.trim().toLowerCase()
            if (txt.length > 2) {
              allowed = allowed && job.full_address.toLowerCase().includes(txt)
            }
          }
          if (this.filters.status && job.status_for_filter) {
            if (this.filters.status != '') {
              allowed = allowed && job.status_for_filter[this.filters.status]
            }
          }
          return allowed
        }
      }
      return true
    }
  }
}
</script>
