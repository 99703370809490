<template>
  <button type="button" class="cal-notification-button"
    @click="changedUrl"
    v-b-tooltip.hover
    :title="numberOfEmails > 0 ? `There ${number} pending notifications, please click here to manage them.` : 'No pending notifications.' ">
    <i class="fal fa-bolt" :class="{ 'fas fa-bolt green-text': numberOfEmailsComputed > 0 }"></i>
  </button>
</template>
<script>
import api from '@/clients/api'
export default {
  props: {
    locationId: {
      type: Number
    }
  },
  data() {
    return {
      numberOfEmails: 0
    }
  },
  computed: {
    numberOfEmailsComputed() {
      return this.numberOfEmails
    },
    number() {
      if (this.numberOfEmails > 1) {
        return 'are ' + this.numberOfEmails + ' notification emails'
      }
      return 'is ' + this.numberOfEmails + ' notification email'
    }
  },
  mounted() {
    this.getNotifications(this.locationId)
  },
  methods: {
    getNotifications(locationId) {
      api.getNotificationsForProperty(locationId).then((response) => {
        if (response.data) {
          this.numberOfEmails = response.data.length
        }
      })
    },
    changedUrl() {
      window.open('/changes' , '_blank')
    }
  },
  watch: {
    locationId(newLocationId) {
      console.log('locationId - newLocationId', newLocationId)
      if (newLocationId) {
        this.getNotifications(newLocationId)
      }
    }
  }
}
</script>
