<template>
  <b-popover v-if="domId" :target="domId">
    <div class="popup-wrapper">
      <div class="popup-header"><button type="button" class="close" @click="$root.$emit('bv::hide::popover')">×</button>
        <span>Reservations list for <br> {{ dateString }}</span>
      </div>
      <div class="popup-body reservations-list">
       <event class="my-1 pl-1" v-for="(event,index) in events" :key="event.dom_id" :event="event" @event-click="$emit('event-click', $event)"/>
      </div>
    </div>
  </b-popover>
</template>
<script>
  import Event from './event.vue'
  export default {
    components: { Event },
    props: ['domId', 'events', 'date'],
    computed: {
      dateString() {
        return this.date.toString('MMMM dd, yyyy')
      }
    }
}
</script>
