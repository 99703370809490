<template>
  <div class="row">
    <div class="col-sm-2">{{tasks}}</div>
    <div class="col-sm-6">{{name}}</div>
    <div class="col-sm-2">
      <input v-if="hasTasks" v-model="sendText" type="checkbox">
      <span v-else>-</span>
    </div>
    <div class="col-sm-2">
      <input v-if="hasTasks" v-model="sendEmail" type="checkbox">
      <span v-else>-</span>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    row: {
      type: Object,
      required: true
    }
  },
  data() {
    return { email: false, text: false }
  },
  mounted() {
    this.email = false
    this.text = false
  },
  computed: {
    hasTasks() {
      return this.row.tasks > 0
    },
    tasks() {
      if (this.hasTasks) {
        return this.row.tasks
      }
      return '-'
    },
    name() {
      return this.row.name
    },
    sendEmail: {
      get() {
        return this.email
      },
      set(v) {
        this.$emit('update', {
          jobIds: this.row.job_ids,
          cleanerId: this.row.cleaner_id,
          method: 'email',
          state: v
        })
        this.email = v
      }
    },
    sendText: {
      get() {
        return this.text
      },
      set(v) {
        this.$emit('update', {
          jobIds: this.row.job_ids,
          cleanerId: this.row.cleaner_id,
          method: 'text',
          state: v
        })
        this.text = v
      }
    }
  }
}
</script>
