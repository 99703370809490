<template>
  <div v-if="show" class="row-item" :class="showAvailabilities">
    <component
      v-bind:is="jobType"
      @assign-cleaner="$emit('assign-cleaner', job.id)"
      @unassign-cleaner="$emit('unassign-cleaner', $event)"
      @event-click="$emit('event-click', $event)"
      @complete="$emit('complete', $event)"
      v-for="(job, idx) in jobs" :job="job"
      :dom-id="'j-' + job.id"
      :key="'job_'+job.id"
      :for-cleaner="forCleaner"
    />
  </div>
</template>
<script>
import AssignedJobCell from './assigned_job_cell.vue'
import UnassignedJobCell from './unassigned_job_cell.vue'
export default {
  components: {
    AssignedJobCell, UnassignedJobCell
  },
  props: {
    jobs: {
      type: Array
    },
    forCleaner: {
      type: Boolean,
      default: false
    },
    indexHour: {
      type: Object
    },
    availableHours: {
      type: Array
    },
    forUnassigned: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    colspan() {
      //// calculate the value based on the maximum duration?
      return 1
    },
    jobType() {
      return this.forUnassigned ? 'unassigned-job-cell' : 'assigned-job-cell'
    },
    showAvailabilities() {
      if (typeof this.indexHour === 'undefined' && typeof this.availableHours === 'undefined') {
        console.log('Both are undefined')
        return
      }
      if (this.availableHours.indexOf(this.indexHour.hour24) != -1) {
        return 'user-available'
      } else {
        return 'user-unavailable'
      }
    }
  }
}
</script>
