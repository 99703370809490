<template>
  <div>
    <base-modal classes="calendar-modal background-soft-gray" dom-id="confirmationModal" :show="show" :title="title" @close="$emit('close')">
      <div slot="content">
        <div v-if="loading" class="row">
          <div class="col-12">
            <clip-loader :loading="loading"></clip-loader>
          </div>
        </div>
        <div v-if="!loading" class="row">
          <div class="col-12 table-responsive">
            <p class="info-text table">{{initialHeader}}</p>
            <p class="info-text table">{{upcomingTitle}}</p>
            <table v-if="isRecurring" class="table table-striped">
              <thead>
              <tr>
                <th width="50%">Date</th>
                <th width="50%">Time</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="visit in visits">
                <td>{{visit.date}}</td>
                <td>{{visit.time}}</td>
              </tr>
            </tbody>
            </table>
          </div>
        </div>
        <p v-if="!loading">{{explanation}}</p>
      </div>
      <div v-if="!loading" slot="footer">
        <button type="button" class="btn btn-success" @click="confirmPending">{{confirmButtonTitle}}</button>
        <button type="button" class="btn btn-warning" @click="$emit('change-dates')">Back to Calendar</button>
      </div>
    </base-modal>
  </div>
</template>
<script>
import { ClipLoader } from 'vue-spinner/dist/vue-spinner.min.js'
import BaseModal from '@/modals/base_modal.vue'
export default {
  props: {
    eventId: {
      type: Number,
      default: null
    },
    confirmMode: {
      type: String,
      required: true
    },
    forClient: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  components: { BaseModal, ClipLoader },
  computed: {
    confirmButtonTitle() {
      if (this.isRecurring) {
        return 'Confirm Schedule'
      }
      return 'Confirm Reservation'
    },
    explanation() {
      if (this.isRecurring) {
        return 'You are confirming a pending schedule. Click on the "Confirm Schedule" button below if you checked the location and availability or click "Change Dates" to reschedule.'
      }
      return 'You are confirming a pending reservation. Click on the "Confirm Reservation" button to confirm or click "Change Dates" to reschedule.'
    },
    isRecurring() {
      return this.confirmMode == 'pending-schedule'
    },
    title() {
      if (this.isRecurring) {
        return 'Review the schedule'
      }
      return 'Confirming Reservation'
    },
    initialHeader() {
      if (this.event) {
        if (this.isRecurring) {
          return "Initial Cleaning: " + this.event.initial_start
        }
      }
    },
    upcomingTitle() {
      if (this.isRecurring) {
        return 'The recurring cleaning schedule will be the following:'
      }
    },
    visits() {
      if (this.event) {
        return this.event.upcoming_visits
      }
      return []
    }
  },
  data() {
    return {
      loading: true,
      event: null
    }
  },
  methods: {
    confirmPending() {
      this.loading = true
      this.$http.put('/reservations/' + this.eventId + '/confirm_pending').then(response => {
        this.$emit('reservation-updated')
        this.loading = false
      })
    },
    loadEvent() {
      this.loading = true
      this.$http.get('/reservations/' + this.eventId).then(response => {
        this.event = response.data
        this.loading = false
      })
    },
  },
  watch: {
    show(new_value, old_value) {
      if (new_value) {
        this.loadEvent()
      }
    }
  }
}
</script>
