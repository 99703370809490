<template>
  <div class="cal-table-col col-popup"  :class="currentDate">
    <div class="col-div cell-mobile">
      <div :class="currentDate" v-if="(numberOfVisits == 1)" @click="$emit('event-click', events[0].id)">
        <div class="calendar-date" :class="dayActiveBg(numberOfVisits, events)" :id="popoverId.toString()">
          <span >{{ day }}</span>
          <spinner v-if="loading" size="30px" message="" />
        </div>
      </div>
      <div v-else :class="currentDate">
        <div class="calendar-date" :class="dayActiveBg(numberOfVisits, events)" :id="popoverId.toString()">
          <span>{{ day }}</span>
          <spinner v-if="loading" size="30px" message="" />
          <list-event-popup v-if="(numberOfVisits > 0)" :domId="popoverId.toString()" :events="events" :date="dayData.date" @event-click="$emit('event-click', $event)"></list-event-popup>
        </div>
      </div>
      <div class="calendar-day-info">
        <div class="calendar-reservation-count">
          <span v-if="forAdmins && (numberOfVisits > 0)" @click="showInfo != showInfo" class="col-visit">{{numberOfVisits}} Visits</span>
          <div v-if="showInfo" class="col-visit" v-html="visitInfo"></div>
        </div>
        <div class="calendar-reservation-links">
          <!-- <event v-for="(event,index) in events" :key="event.dom_id" :event="event" @event-click="$emit('event-click', $event)"/> -->
        </div>
      </div>
    </div>
    <div class="col-div cell-desktop">
      <div>
        <span>{{day}}</span>
        <spinner v-if="loading" size="30px" message="" />
        <span v-if="forAdmins && (numberOfVisits > 0)" @click="showInfo = !showInfo" class="col-visit">{{numberOfVisits}} Visits</span>
        <div v-if="showInfo" class="col-visit" v-html="visitInfo"></div>
        <event v-for="(event, index) in events" :key="event.dom_id" :event="event" @event-click="$emit('event-click', $event)"/>
      </div>
    </div>
  </div>
</template>
<script>
  import Spinner from '@/shared/non_modal_spinner.vue'
  import Event from './event.vue'
  import ListEventPopup from './ListEventPopup.vue'

  export default {
    props: [ 'dayData', 'showCancelled' ],
    components: { Event, ListEventPopup, Spinner },
    data() {
      return {
        showInfo: false
      }
    },
    computed: {
      day() {
        return this.dayData.date.getDate();
      },
      events() {
        return this.dayData.eventData ? this.dayData.eventData : [];
      },
      forAdmins() {
        return !this.forClients
      },
      forClients() {
        return this.isClient
      },
      loading() {
        if (this.forAdmins) {
          return this.dayData.loading
        }
      },
      numberOfVisits() {
        if (this.visitIds) {
          return this.visitIds.length
        }
        return this.events.length
      },
      visitIds() {
        return this.dayData.visitIds
      },
      popoverId() {
        return this.dayData.id
      },
      currentDate() {
        if(this.dayData.date.isToday()){
          return 'current-date'
        }
        if(this.dayData.date < new Date()){
          return 'past-date'
        }
      },
      unassigned() {
        return this.events.filter(visit => visit.has_job == false)
      },
      completed() {
        return this.events.filter(visit => visit.job_completed)
      },
      cancelled() {
        return this.events.filter(visit => visit.cancelled)
      },
      cancelledCount() {
        return this.cancelled.length
      },
      completedCount() {
        return this.completed.length
      },
      unassignedCount() {
        return this.unassigned.length
      },
      visitInfo() {
        let info = 'Unassigned: ' + this.unassignedCount + '<br>Completed: ' + this.completedCount
        if (this.showCancelled) {
          info += '<br>Cancelled: ' + this.cancelledCount
        }
        return info
      }
    },
    methods: {
      dayActiveBg(numberOfVisits, events) {
        if (numberOfVisits == 0) {
          return ''
        } else if(numberOfVisits == 1) {
          return `date-active ${events[0].status_color}-bg`
        } else if (numberOfVisits > 1) {
          return 'date-active gray-bg'
        }
      }
    }
  }
</script>
