<template>
  <div>
    <div class="my-2">
      <h1 class="main-head">Active Jobs</h1>
    </div>
    <div class="grid-container">
      <div class="grid-table">
        <div class="grid-header">
          <div class="grid-row">
            <span v-for="hour in hours" :key="hour.hour24" class="grid-col font-weight-bold">{{hour.label}}</span>
          </div>
        </div>
        <div class="grid-body">
          <jobs-in-table-row :hours="hours" :jobs-per-hour="jobs" @complete="$emit('complete', $event)" :for-staff="false"></jobs-in-table-row>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from "vue";
import vuedraggable from "vuedraggable";
import JobsInTableRow from "./jobs_in_table_row.vue";

import {
  dateToAppFormat
} from "@/utils";

export default {
  name: 'GridAsWidgetForCleaner',
  components: {
    vuedraggable,
    JobsInTableRow
  },
  props: {
    hours: {
      type: Array,
      required: true
    },
    jobs: {
      type: Object,
      required: true
    }
  },
  computed: {
    params() {
      var params = {
        start: this.startDate,
        end: this.startDate
      };
      if (this.locationId) {
        params.location_id = this.locationId;
      }
      return params;
    },
    startDate() {
      return dateToAppFormat(this.date);
    },
    title() {
      return this.dateValue.toString("ddd, MMM dS");
    }
  },
  methods: {
  }
};
</script>
