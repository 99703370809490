<template>
  <div>
    <base-modal classes="background-soft-gray" modal-size="" dom-id="assignmentModal" :show="jobId != null" title="Assign Cleaning" @close="$emit('close')">
      <div slot="content">
        <h6 class="alert alert-info text-center">Green button means the cleaner is available, gray means the cleaner is unavailable. Unavailable cleaners will be forced to become available when assigned.</h6>
        <assignment-form :job-id="jobId" @update="$emit('reservation-updated')"></assignment-form>
      </div>
      <div slot="footer">
        <div class="text-center mx-auto">
          <button type="button" class="btn btn-default btn-primary" data-dismiss="modal" aria-label="Close">Close</button>
        </div>
      </div>
    </base-modal>
  </div>
</template>
<script>
import api from '@/clients/api'
import BaseModal from '@/modals/base_modal.vue'
import AssignmentForm from './assignment_form.vue'
export default {
  props: {
    jobId: {
      type: Number
    }
  },
  components: { AssignmentForm, BaseModal },
  methods: {
  },
}
</script>
