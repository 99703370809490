<template>
  <div>
    <div class="v-spinner" style="position: absolute; left: 50%" id="js-init-loader">
      <div class="v-clip" style="height: 35px; width: 35px; border-width: 2px; border-style: solid; border-color: rgb(93, 197, 150) rgb(93, 197, 150) transparent; border-radius: 100%; background: transparent;"></div>
    </div>
    <toggle-notifications-widget v-if="showToggleNotifications"></toggle-notifications-widget>
    <div class="js-hide d-none">
      <div class="container mb-2">
        <div class="calendar-head">
          <div class="cal-header-switch-group">
            <button type="button" class="cal-agendaWeek-button cal-button" :class="{'cal-button-active': isUpcomingVisitsView}" @click="setUpcomingVisitsView">upcoming visits</button>
            <button v-show="!isMonthView" type="button" class="cal-agendaWeek-button cal-button" :class="{'cal-button-active': isPastVisitsView}" @click="setPastVisitsView">past visits</button>
          </div>
          <div class="cal-header-switch-group">
            <button type="button" class="cal-month-button cal-button" :class="{'cal-button-active': isMonthView}" @click="setMonthView">monthly view</button>
            <button v-if="isStaff" type="button" class="cal-agendaWeek-button cal-button" :class="{'cal-button-active': isGridView}" @click="setGridView">grid view</button>
          </div>
          <div class="cal-header-switch-group">
            <button @click="today" type="button" class="cal-month-button cal-button" :class="{'cal-button-active': isCurrentDay}">Today</button>
          </div>
          <button v-if="!isVisitsView" type="button" class="cal-refresh-button" @click="refreshData">
            <i class="fal fa-sync-alt" :class="{ 'fa-spin': loading }"></i>
          </button>

          <!-- Quick visit -->
          <button v-if="isClient" type="button" class="cal-refresh-button" @click="goToNewBooking()"
            v-b-tooltip.hover :title="'Schedule a visit'">
            <i class="fal fa-plus"></i>
          </button>

          <pending-notifications :key="pendingNotifications" v-if="showPendingNotifications" :location-id="mutableLocationId"></pending-notifications>
          <div class="cal-h-center">
            <text-date-picker class="d-none d-lg-block" :value="date" :title="title" @new-date="goToDate"></text-date-picker>
          </div>
          <div class="cal-h-right">
            <div v-if="!isVisitsView" class="d-none d-lg-block">
              <button type="button" class="cal-prev-button cal-button" aria-label="prev" @click="prev"><i class="fal fa-chevron-left"></i></button>
              <button type="button" class="cal-next-button cal-button" aria-label="next" @click="next"><i class="fal fa-chevron-right"></i></button>
            </div>
            <div class="d-flex justify-content-center d-lg-none d-xl-none">
              <button v-if="!isVisitsView" type="button" class="cal-prev-button cal-button" aria-label="prev" @click="prev"><i class="fal fa-chevron-left"></i></button>
              <text-date-picker :value="date" :title="title" @new-date="goToDate"></text-date-picker>
              <button v-if="!isVisitsView" type="button" class="cal-next-button cal-button" aria-label="next" @click="next"><i class="fal fa-chevron-right"></i></button>
            </div>
          </div>
        </div>
        <!-- Info about calendar migration -->
        <div v-if="isClient" class="alert alert-info" role="alert">
          If you don't see your visits in your portal, don't worry just let us know in the chatbot below or contact us at <a style="white-space: nowrap;" :href="companyPhoneHref">{{ companyPhone }}</a>.
        </div>
        <!-- End Info about calendar migration -->
        <property-switcher v-if="showPropertySwitcher" :client-id="clientId" @location-id:change="mutableLocationId = $event"></property-switcher>
        <div class="admin-calendar" :class="{'cal-table-month': isMonthView, 'cal-table-week': isWeekView}">
          <div v-if="showAreaSwitcher || showStatusFilter" class="calendar-head-top mb-2">
            <div class="row">
              <div class="col-md-4 col-sm-12">
                <div v-if="showAreaSwitcher" class="full-select display-block my-1">
                  <multiselect v-if="!loading" 
                    @select="onAreaPick"
                    @remove="onAreaRemove"
                    :value="selectedAreas"
                    :options="area_options"
                    :multiple="true"
                    label="label" 
                    placeholder="All Areas"
                    track-by="value"
                    :searchable="false"
                    ></multiselect>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div v-if="showStatusFilter" class="full-select display-block my-1">
                  <round-dropdown v-model="status" :options="filter_options" cssClass="''"/>
                </div>
              </div>
              <div class="col-md-4 col-sm-12">
                <div v-if="showStatusFilter" class="full-select display-block my-1">
                  <job-status-filter @update="jobStatusFilter = $event" />
                </div>
              </div>
            </div>
          </div>
          <magic-url v-if="client" :url="client.short_review_calendar_url" title="Access your calendar"></magic-url>
          <magic-url v-if="client" :url="client.short_start_booking_url" title="Start booking"></magic-url>
          <pending-warning v-if="!isVisitsView" :pending-properties="pendingProperties"></pending-warning>
          <template v-if="!isGridView">
            <label>Show Cancelled</label>
            <input v-model="showCancelled" type="checkbox" />
          </template>
          <area-switches v-show="showAreaSwitches && !loading" @update="onAccessibleAreasChanged" />
          <div class="cal-table-container sticky-header" v-if="isMonthView">
            <div class="cal-table-head">
              <div class="cal-table-row">
                <div class="cal-table-col" v-for="day in Days">
                  <div class="col-head-div">
                    <span class="col-head">{{day}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cal-table-body">
              <div v-if="loading" class="cal-table-row">
                <clip-loader :loading="loading" style="margin-left: 50%"></clip-loader>
              </div>
              <div v-if="!loading" class="cal-table-row" v-for="week in daysByWeek">
                <monthly-day-cell
                  v-for="dayData in week"
                  :key="dayData.date.toDateString()"
                  :show-cancelled="showCancelled"
                  :day-data="dayData"
                  @event-click="clickedEventId = $event"
                />
              </div>
            </div>
          </div>
          <div class="cal-table-container" v-if="isWeekView">
            <div class="cal-table-head">
              <div class="cal-table-row">
                <div class="cal-table-col" v-for="day in Days">
                  <div class="col-head-div">
                    <span class="col-head">{{day.name}}</span>
                    <span class="col-sub-head">{{day.date}}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="cal-table-body">
              <div class="cal-table-time">
                <div class="col-time-div" v-for="hour in Hours">{{hour.label}}</div>
              </div>
              <div class="cal-table-row" v-for="hour in Hours">
                <weekly-day-cell v-for="(dayData, index) in weekByHours[hour.hour24]" :key="dayData.dayIdx" :day-data="dayData" :for-css="{ idx: index, hour24: hour.hour24 }"></weekly-day-cell>
              </div>
            </div>
          </div>
        </div>
        <visits v-if="isVisitsView"
          ref="visits"
          :start-date="startDate.toString('d')"
          :end-date="endDate.toString('d')"
          :direction="visitsDirection"
          :client="client"
          :location-id="mutableLocationId"
          :show-cancelled="showCancelled"
          @event-click="clickedEventId = $event"
          @go-tab="$emit('go-tab', $event)" />
        <template v-if="isGridView">
          <div v-if="loading" class="cal-table-row">
            <clip-loader :loading="loading" style="margin-left: 50%"></clip-loader>
          </div>
          <cleaner-grid v-if="!loading && isCleaner" :hours="Hours" :jobs="jobs" @complete="completeJob"></cleaner-grid>
          <staff-grid
            v-if="!loading && !isCleaner"
            :still-loading="dataLoading"
            :title="title"
            :hours="Hours"
            :teams="teams"
            :jobs-per-team="assignedJobsPerTeam"
            :unassigned-jobs="unassignedJobs"
            :requests="requests"
            :counts="counts"
            @event-click="clickedEventId = $event"
            @assign:cleaner="assignCleaner"
            @unassign-cleaner="unassignCleaner"
            @show-daysheets="openDaySheetsModal"
            @send:daysheet-text="sendDaysheetTextToCleaner"
            @show-requests="onShowRequests"
          ></staff-grid>
        </template>
      </div>

      <event-modal
        :show="showEventModal"
        :event-id="clickedEventId"
        :init-tab="effectiveVisitModalTab"
        @close="onCloseEventModal"
        @reservation-updated="onReservationUpdated"
        @confirm="enableConfirmationModal(...arguments)"
        @assign-area="assignEventId = clickedEventId"
        @reload-needed="reloadNeeded = true"
        @visit-notes:updated="$emit('visit-notes:updated')" />

      <template v-if="isStaff">
        <confirmation-modal
          :show="showConfirmationModal"
          :event-id="clickedEventId"
          :confirm-mode="confirmMode"
          :for-client="isClient"
          @reservation-updated="onReservationUpdated"
          @change-dates="onChangeDates"
          @close="confirmMode=''"></confirmation-modal>
        <area-assignment-modal
          :show="showAreaAssignmentModal"
          :event-id="assignEventId"
          :map-url="mapUrl"
          :request-event-id="requestEventId"
          :property-id="propertyId"
          @reservation-updated="onReservationUpdated"
          @close="closeAreaAssignmentModal"></area-assignment-modal>
      </template>
      <template v-if="isGridView">
        <assignment-modal
          :job-id="jobIdToAssign"
          :teams="teams"
          @reservation-updated="onReservationUpdated"
          @close="jobIdToAssign = null"></assignment-modal>
        <day-sheets-modal
          :show="showDaySheetsModal"
          :date="date"
          :service-area-ids="serviceAreaIds"
          @send:daysheet="sendDaysheet"
          @close="closeDaySheetsModal"></day-sheets-modal>
        <request-modal
          :requests="requests"
          :show-type="showRequestTypes"
          :show="showRequestModal"
          @do-action="doAction"
          @remove="doRemove"
          @close="closeRequestModal"></request-modal>
        <incomplete-jobs-modal
          :info="cleanersWithIncompleteJobs"
          :show="showIncompleteJobsModal"
          @close="showIncompleteJobsModal = false"></incomplete-jobs-modal>
      </template>
      <div class="calendar-btm" v-if="!isVisitsView">
        <div><span class="green-bg"></span>Standard Cleaning</div>
        <div><span class="blue-bg"></span>Upkeep Cleaning</div>
        <div><span class="black-bg"></span>ICAL</div>
        <div><span class="orange-bg"></span>Pending Confirmation</div>
        <div><span class="red-bg"></span>Cancelled</div>
      </div>
    </div>
  </div>
</template>
<script>
import $ from 'jquery'
import Vue from 'vue'
import adminApi from '@/admins/api'
import Interceptors from '@/interceptors.js'
import { cal, dateToAppFormat, Days, onlyUnique, dynamicSort, getObjByValue } from '@/utils'
import Multiselect from 'vue-multiselect'
import modal from 'vue-semantic-modal'
import company_phone_mixin from '@/shared/company_phone_mixin'

import VueResource from 'vue-resource/dist/vue-resource'
Vue.use(VueResource)

import infiniteScroll from 'vue-infinite-scroll'
Vue.use(infiniteScroll)

import { ClipLoader } from 'vue-spinner/dist/vue-spinner.min.js'

import MonthlyDayCell from './MonthlyDayCell.vue'
import WeeklyDayCell from './WeeklyDayCell.vue'
import Visits from '@/visits/AsList.vue'
import EventModal from './event_modal.vue'
import PendingNotifications from './pending_notifications.vue'
import StaffGrid from '@/grid/as_widget.vue'
import CleanerGrid from '@/grid/as_widget_for_cleaner.vue'
import ConfirmationModal from './confirmation_modal.vue'
import AreaAssignmentModal from './area_assignment_modal.vue'
import AssignmentModal from '@/grid/assignment_modal.vue'
import DaySheetsModal from '@/grid/day_sheets_modal.vue'
import IncompleteJobsModal from '@/grid/incomplete_jobs_modal.vue'
import ScheduleForm from '@/schedule/form_for_calendars.vue'
import PropertySwitcher from './property_switcher.vue'
import PendingWarning from '@/shared/pending_warning.vue'
import MagicUrl from '@/shared/magic_url.vue'
import RoundDropdown from '@/shared/fields/round_dropdown.vue'
import AreaSwitches from '@/shared/area_switches.vue'
import JobStatusFilter from '@/shared/job_status_filter.vue'
import ToggleNotificationsWidget from '@/shared/toggle_notifications_widget.vue'
import TextDatePicker from '@/shared/text_date_picker.vue'
import RequestModal from '@/grid/request_modal.vue'

Vue.component('RoundDropdown', RoundDropdown)
Vue.component('clip-loader', ClipLoader)

var days = []


export default {
  props: {
    clientId: {
      type: Number
    },
    locationId: {
      type: Number | String
    },
    goToTab: {
      type: String
    }
  },
  components: {
    AreaAssignmentModal,
    AssignmentModal,
    AreaSwitches,
    JobStatusFilter,
    ConfirmationModal,
    DaySheetsModal,
    EventModal,
    modal,
    CleanerGrid,
    StaffGrid,
    WeeklyDayCell,
    MagicUrl,
    MonthlyDayCell,
    ScheduleForm,
    PendingNotifications,
    PendingWarning,
    PropertySwitcher,
    Visits,
    ToggleNotificationsWidget,
    TextDatePicker,
    RequestModal,
    IncompleteJobsModal,
    Multiselect
  },
  mixins: [company_phone_mixin],
  data() {
    return {
      dataGroup: 0,
      client: null,
      cleanerId: null,
      pendingNotifications: 0,
      mutableLocationId: this.locationId,
      clickedEventId: null,
      assignEventId: null,
      assignEvent: null,
      lastUpdatedEventId: null,
      daysByWeek: [],
      weekByHours: [],
      calendar: null,
      Hours: [],
      date: dateToAppFormat(Date.today()),
      areas: [],
      serviceAreaIds: [],
      area_options: [],
      filter_options: [{
        value: 'all',
        label: 'All Bookings'
      }, {
        value: 'instant',
        label: 'Instant Bookings'
      }, {
        value: 'confirm',
        label: 'Confirmation Required'
      }],
      jobStatusFilter: '',
      loading: false,
      refreshing: false,
      status: 'all',
      closeOnClickAway: true,
      showModal: false,
      showDaySheetsModal: false,
      showRequestTypes: null,
      showIncompleteJobsModal: false,
      pendingProperties: [],
      popupTarget: null,
      show: false,
      showCancelled: false,
      confirmed: true,
      showCloseIcon: true,
      confirmMode: '',
      selectedEvent: null,
      reloadNeeded: false,
      viewMode: 'month',
      visitsDirection: 'upcoming',
      selectedAreaIds: [],
      visitModalTab: null
    }
  },
  activated() {
    if (Window.initVisitId) {
      this.clickedEventId = Window.initVisitId
    }
  },
  created() {
    Vue.http.interceptors.push(Interceptors.csrf_and_disable_caching)
    Vue.http.interceptor.before = Interceptors.track_requests
    window.addEventListener('beforeunload', this.sendVisitChangesEmail)
  },
  mounted() {
    $('.js-hide').removeClass('d-none')
    $('#js-init-loader').remove()
    this.$root.$on('bv::popover::show', () => {
      this.$root.$emit('bv::hide::popover')
    })
    this.init()
  },
  computed: {
    baseUrl() {
      if (this.isGridView) {
        return '/api/grid'
      }
      return '/api/calendar'
    },
    currentDays() {
      const year = Date.parse(this.date).getFullYear()
      const month = Date.parse(this.date).getMonth()

      var days = cal.monthDates(year, month, function(d) {
        return d
      })
      return days
    },
    dataLoading() {
      return ( (this.reservationIds.length > 0) && (this.loadedReservations != this.reservationIds.length) )
             || ( (this.teamIds.length > 0) && (this.teamIds.length != this.teams.length) )
    },
    dateValue() {
      return Date.parse(this.date)
    },
    dataLoading() {
      return ( (this.reservationIds.length > 0) && (this.loadedReservations != this.reservationIds.length) )
             || ( (this.teamIds.length > 0) && (this.teamIds.length != this.teams.length) )
    },
    effectiveVisitModalTab() {
      if (this.visitModalTab) {
        return this.visitModalTab
      }
      return this.goToTab
    },
    eventFilters() {
      return { job: this.jobStatusFilter }
    },
    forProperty() {
      return !!this.locationId
    },
    showPendingNotifications() {
      return this.isAdmin
    },
    showToggleNotifications() {
      const onPropertyPage = location.pathname.startsWith("/properties")
      return this.isStaff && !onPropertyPage
    },
    title() {
      if (this.isVisitsView || this.isWeekView) {
        var startFormat = 'MMM d, yyyy'
        var endFormat = 'MMM d, yyyy'
        var endD = Date.parse(this.endDate).add(1).days()
        var startD = Date.parse(this.startDate)

        if (startD.getFullYear() == endD.getFullYear()) {
          startFormat = startFormat.replace(', yyyy', '')
        }
        if (startD.getMonth() == endD.getMonth()) {
          endFormat = endFormat.replace('MMM', '')
        }

        return startD.toString(startFormat) + ' - ' + endD.toString(endFormat)
      }

      if (this.isGridView) {
        return this.dateValue.toString('dS of MMMM yyyy')
      }
      return this.dateValue.toString('MMMM yyyy')
    },
    isGridView() {
      return this.viewMode == 'grid'
    },
    isMonthView() {
      return this.viewMode == 'month'
    },
    isWeekView() {
      return this.viewMode == 'week'
    },
    isVisitsView() {
      return this.viewMode == 'visits'
    },
    isUpcomingVisitsView() {
      return this.isVisitsView && this.visitsDirection == 'upcoming'
    },
    isPastVisitsView() {
      return this.isVisitsView && this.visitsDirection == 'past'
    },
    isRescheduleMode() {
      return this.rescheduleMode
    },
    mapUrl() {
      if (this.assignEvent) {
        return this.assignEvent.mapUrl
      }
    },
    propertyId() {
      if (this.assignEvent) {
        return this.assignEvent.propertyId
      }
    },
    requestEventId() {
      if (this.assignEvent) {
        return this.assignEvent.id
      }
    },
    navIncrement() {
      if (this.isWeekView) {
        return 'weeks'
      }
      if (this.isGridView) {
        return 'days'
      }
      return 'months'
    },
    showAreaSwitcher() {
      if (this.forProperty) {
        return false
      }

      if (this.isStaff && (this.area_options.length > 0)) {
        return (this.areas.length > 1) && this.isStaff && (this.clientId == null)
      }
    },
    showAreaSwitches() {
      if (this.forProperty) {
        return false
      }

      return this.isGlobalAdmin
    },
    showStatusFilter() {
      return this.isAdmin && this.isMonthView
    },
    showPropertySwitcher() {
      return this.isClient
    },
    showAreaAssignmentModal() {
      return !!this.assignEventId || !!this.requestEventId
    },
    showConfirmationModal() {
      return this.showEventModal && (this.confirmMode != '')
    },
    showEventModal() {
      return !!this.clickedEventId
    },
    showRequestModal() {
      return !!this.showRequestTypes
    },
    startDate() {
      if (this.isVisitsView) {
        if (this.isUpcomingVisitsView) {
          return dateToAppFormat(Date.today())
        }
        return dateToAppFormat(Date.parse('yesterday').addMonths(-1))
      }
      if (this.isGridView) {
        return dateToAppFormat(this.dateValue)
      }
      return dateToAppFormat(this.currentDays[0][0])
    },
    endDate() {
      if (this.isVisitsView) {
        if (this.isUpcomingVisitsView) {
          return dateToAppFormat(Date.today().addMonths(1))
        }
        return dateToAppFormat(Date.parse('yesterday'))
      }
      if (this.isGridView) {
        return this.startDate
      }
      const lastWeek = this.currentDays[this.currentDays.length - 1]
      return dateToAppFormat(lastWeek[lastWeek.length - 1])
    },
    Days() {
      if (this.isMonthView)
        return Days
      var days = []
      var date = Date.parse(this.startDate)
      Array.prototype.forEach.call(Days, function(d) {
        days.push({
          name: d.substr(0, 3),
          date: date.toString('M/dd')
        })
        date.add(1).day()
      })
      return days
    },
    selectedAreas() {
      return this.serviceAreaIds.map(areaId => getObjByValue(this.area_options, areaId))
    },
    params() {
      let params = {
        show_cancelled: this.showCancelled,
        date: this.date
      }
      if (this.serviceAreaIds.length > 0) {
        params.service_area_ids = this.serviceAreaIds
      }
      if (this.status) {
        params.status = this.status
      }
      if (this.clientId) {
        params.client_id = this.clientId
      }
      if (this.cleanerId) {
        params.cleaner_id = this.cleanerId
      }
      if (this.mutableLocationId) {
        params.location_id = this.mutableLocationId
      }
      return params
    },
    isCurrentDay() {
      return this.date === dateToAppFormat(Date.today())
    }
  },
  methods: {
    doAction({ action, event }) {
      this.closeAreaAssignmentModal()
      if (this[action.do]) {
        this[action.do](event)
      } else {
        console.error('calendar/Base doAction action "',action.do,'" does not exist')
      }
    },
    assignZip(event) {
      this.showRequestTypes = null
      this.assignEvent = event
    },
    doRemove({ event, reason }) {
      this.showRequestTypes = null
      this.loading = true
      this.$http.delete('/api/events/' + event.id, { params: { reason: reason } }).then(response => {
        this.refreshData()
      })
    },
    // FIXME we do not really need the property id here, because it is in the
    // event, so the whole action could be moved to another controller
    // the propertyId is not even used in the controller when the event_id passed
    new_reservation(event) {
      window.location = '/properties/0/book?event_id=' + event.id
    },
    closeAreaAssignmentModal() {
      this.showRequestTypes = null
      this.assignEvent = null
    },
    getAccessibleAreas() {
      this.loading = true
      adminApi.getAccessibleAreas().then(response => {
        this.area_options = [{
          value: '',
          label: 'All Areas'
        }]

        Array.prototype.forEach.call(response.data, (a) => {
          this.area_options.push({
            value: a.id.toString(),
            label: a.name
          })
          this.areas.push(a)
        })
        if (this.areas.length == 1) {
          Vue.set(this.serviceAreaIds, [ this.areas[0].id ])
        }
        this.loading = false
      })
    },
    init() {
      console.log("Base init")
      if (this.isStaff) {
        this.getAccessibleAreas()
      }

      if ( (location.pathname == '/grid') || (location.pathname == '/')) {
        this.viewMode = 'grid'
      } else {
        this.initView()
      }
      //if (!this.isStaff && !this.isClient) {
        this.loadData()
      //}
    },
    initView() {
      if (this.isGridView) {
        console.log('initView for grid')
        this.initHours()
        this.loadCleaners()
      }
      if (this.isMonthView) {
        console.log('initView for month')
        this.initMonthView()
      }
    },
    initHours() {
      if (this.Hours && (this.Hours.length > 0)) {
        console.log('initHours already initialized')
        return
      }
      console.log('initHours', this.Hours)
      this.loading = true
      this.$http.get('/api/grid').then(response => {
        let idx = 0
        for (let i = response.data.hours.start; i <= response.data.hours.end; i++) {
          const date = Date.today().set({
            hour: i
          }).toString('h tt')
          Vue.set(this.Hours, idx, { hour24: i, label: date })
          idx += 1
        }
      }, response => {
        console.log("initHours ERROR")
      })
      this.loading = false
    },
    initMonthView() {
      console.log('initMonthView', this.weekByHours)
      this.weekByHours = []
      const week = Date.parse(this.startDate).getWeek()
      var pendingProperties = []

      Array.prototype.forEach.call(this.Hours, (h) => {
        var a = [ [], [], [], [], [], [], [] ]
        this.weekByHours[h.hour24] = a
      })

      var idx = 0
      this.daysByWeek = []
      Array.prototype.forEach.call(this.currentDays, (weeks) => {
        var weekData = []
        Array.prototype.forEach.call(weeks, (d) => {
          const dm = dateToAppFormat(d)
          var event = {
            id: idx,
            date: d,
            loading: true,
            domId: '',
            eventData: []
          }
          idx = idx + 1
          weekData.push(event)
        })
        this.daysByWeek.push(weekData)
      })

      this.pendingProperties = pendingProperties.filter(onlyUnique)
    },
    loadClient() {
      var url = '/api/login'
      this.loading = true
      if (this.clientId) {
        url = '/api/clients/' + this.clientId
      }
      this.$http.get(url).then(response => {
        this.loading = false
        this.client = response.data
      }, response => {
        this.loading = false
        console.error(response.body)
        this.$flasher.error('Could not load the data')
      })
    },
    domId(reservation) {
      if (reservation && reservation.id) {
        return 'event_' + reservation.id + '_' + reservation.when
      }
      return ''
    },
    refreshData() {
      if (this.isGridView) {
        if (this.refreshing) {
          console.log('base refreshData for grid is already running')
          return
        }
        this.refreshing = true
        console.log('base refreshData for grid')
        this.loading = true
        let localParams = Object.assign({}, this.params)
        localParams.group = 2
        this.$http.get(this.baseUrl, {
          params: localParams
        }).then(response => {
          this.processLoadedDataForGrid(response.data)
          this.loading = false
          this.refreshing = false
        }, response => {
          console.log("refreshData ERROR")
        })
        return
      }
      console.log('base refreshData')
      this.loadData()
    },
    loadData() {
      console.log("base loadData is running")
      this.loadVisits()
    },
    loadVisitForDate(dayData) {
      let localParams = Object.assign({}, this.params)
      localParams.date = dateToAppFormat(dayData.date)
      this.$http.get(this.baseUrl, {
        params: localParams
      }).then(response => {
        if (response.data.reservation_ids) {
          Vue.set(dayData, 'visitIds', response.data.reservation_ids)
        }

        Vue.set(dayData, 'eventData', response.data.reservations)
        Vue.set(dayData, 'loading', false)
      }, response => {
        console.log("loadVisitForDate ERROR")
      })
    },
    loadVisits() {
      console.log("base loadVisits")
      let localParams = Object.assign({}, this.params)
      localParams.group = 2
      this.loading = true
      this.$http.get(this.baseUrl, {
        params: localParams
      }).then(response => {
        this.processLoadedData(response.data)
        this.loading = false
      }, response => {
        this.loading = false
        console.error(response.body)
        this.$flasher.error('Could not load the data')
      })
    },
    prev() {
      var inc = {}
      inc[this.navIncrement] = -1
      this.date = dateToAppFormat(Date.parse(this.date).add(inc))
    },
    today() {
      this.date = dateToAppFormat(Date.today())
    },
    next() {
      var inc = {}
      inc[this.navIncrement] = 1
      this.date = dateToAppFormat(Date.parse(this.date).add(inc))
    },
    goToDate(newDate) {
      this.date = newDate
    },
    viewRequest(event) {
      this.visitModalTab = 'updates'
      this.clickedEventId = event.visitId
      this.reloadNeeded = true
    },
    resetGrid() {
      this.teamIds = []
      this.teams = []
      this.reservationIds = []
      this.assignedJobsPerTeam = {}
      this.loadedReservations = 0
      this.unassignedJobs = {}
      this.requests = {}
      this.dataGroup = 0
      Interceptors.cancel_all_requests()
    },
    onReservationUpdated(e) {
      this.sendVisitChangesEmail()
      this.clickedEventId = null
      this.assignEventId = null
      this.confirmMode = ''
      this.reloadNeeded = false
      if (this.$refs.visits) {
        this.$refs.visits.reloadAll()
      }
      this.jobIdToAssign = null
      this.closeAreaAssignmentModal()
      // Activate reload notification component
      this.pendingNotifications += 1

      this.refreshData()
    },
    onChangeDates() {
      this.confirmMode = ''
      this.clickedEventId = null
    },
    //// we use close before the update event triggers
    onCloseEventModal() {
      this.lastUpdatedEventId = this.clickedEventId
      this.clickedEventId = null
      if (Window.initVisitId) {
        this.clickedEventId = null
      }
      if (this.reloadNeeded) {
        this.onReservationUpdated()
      }
    },
    onConfirm(newConfirmMode,b) {
      this.confirmMode = newConfirmMode
    },
    onAccessibleAreasChanged() {
      location.reload()
    },
    onAreaPick({ value, label }) {
      if (value == "") {
        this.serviceAreaIds.splice(0)
      } else {
        let area_ids = this.serviceAreaIds
        area_ids.push(value)
        const uniq = area_ids.filter(onlyUnique)
      }
    },
    onAreaRemove({ value, label }) {
      if (value != "") {
        const idx = this.serviceAreaIds.indexOf(value)
        Vue.delete(this.serviceAreaIds, idx)
      }
    },
    onShowRequests(requestType) {
      this.showRequestTypes = requestType
    },
    setGridView() {
      this.viewMode = 'grid'
      this.loadData()
    },
    setMonthView() {
      this.viewMode = 'month'
      if (this.isMonthView) {
        console.log('setMonthView')
        this.initMonthView()
      }
    },
    setWeekView() {
      this.viewMode = 'week'
    },
    setPastVisitsView() {
      this.viewMode = 'visits'
      this.visitsDirection = 'past'
    },
    setUpcomingVisitsView() {
      this.viewMode = 'visits'
      this.visitsDirection = 'upcoming'
    },
    setVisitsView() {
      this.viewMode = 'visits'
    },
    sendVisitChangesEmail() {
      if (this.lastUpdatedEventId) {
        const url = '/api/reservations/' + this.lastUpdatedEventId + '/notify'
        this.$http.put(url)
      }
    },
    closeRequestModal() {
      this.showRequestTypes = null
    },
    closeDaySheetsModal() {
      this.showDaySheetsModal = false
    },
    openDaySheetsModal() {
      this.showDaySheetsModal = true
    },
    sendDaysheet(params) {
      this.loading = true
      this.$http.post('/api/day_sheets', params).then(response => {
        this.$flasher.success('Daysheets sent')
        this.loading = false
      })
    },
    sendDaysheetTextToCleaner(teamId) {
      var jobsPerHours = this.assignedJobsPerTeam[teamId]
      var jobIds = {}
      if (jobsPerHours) {
        const jobs = Object.values(jobsPerHours)[0]
        jobIds = jobs.map((j) => { return j.cleaning_job_id } )
      }
      if (jobIds && (Object.values(jobIds).length > 0)) {
        const params = {
          date: this.date,
          job_ids: jobIds
        }
        this.loading = true
        this.$http.post('/api/day_sheets/text', params).then(response => {
          this.$flasher.success('Daysheet text sent')
          this.loading = false
        })
      }
    },
    confirm() {
      this.showModal = false
    },
    enableConfirmationModal(what, event) {
      this.confirmMode = what
    },
    processLoadedData(data) {
      console.log("base processLoadedData")
      if (this.isGridView) {
        this.processLoadedDataForGrid(data)

        return
      }

      if (this.isStaff) {
        return
      }

      if (this.isVisitsView) {
        return
      }

      if (data.reservations) {
        console.log("processLoadedData for clients")
        let byDate = {}
        let pendingProperties = []

        Array.prototype.forEach.call(data.reservations, (reservation) => {
          if (reservation.pending) {
            pendingProperties.push(reservation.address)
          }

          let date = dateToAppFormat(Date.parse(reservation.start).clearTime())

          if (byDate[date] === undefined) {
            byDate[date] = []
          }

          byDate[date].push(reservation)
        })

        Object.keys(byDate).forEach((date) => {
          Array.prototype.forEach.call(this.currentDays, (weeks, weekIdx) => {
            let weekData = []
            Array.prototype.forEach.call(weeks, (d, dayIdx) => {
              const dm = dateToAppFormat(d)
              if (dm == date) {
                Vue.set(this.daysByWeek[weekIdx][dayIdx], 'eventData', byDate[date])
              }
            })
          })
        })
      }
    },
    goToNewBooking() {
      this.$router.push({name: 'pick-property'})
    },
    onParamsChanged() {
      if (this.isGridView) {
        this.resetGrid()
      }
      this.initView()
      this.refreshData()
    }
  },
  watch: {
    params(newValue, oldValue) {
      console.log('params changed', oldValue, newValue)
      this.onParamsChanged()
    },
    viewMode(newValue, oldValue) {
      console.log('viewMode changed', oldValue, newValue)
      this.initView()
      this.refreshData()
    }
  }
}
</script>
