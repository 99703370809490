<template>
  <ul v-if="hasCleaners" class="col-desc-rejected list-unstyled mb-0">
    <li v-b-toggle="ourDomId" :class="colorClass">
      {{ prefix }} <i class="float-right fal fa-chevron-down pt-1"></i>
    </li>
    <b-collapse :id="ourDomId">
      <template v-if="rejection">
        <li class="gray-text" @click="$emit('click', cleaner)" v-for="(cleaner, key) in cleaners" :key="key">{{ cleaner.name }}
          <i class="pt-1 red-text float-right fal fa-times-circle"></i>
        </li>
      </template>
      <template v-else>
        <li class="gray-text" v-for="(cleaner, key) in cleaners" :key="key">{{ cleaner.name }}</li>
      </template>
    </b-collapse>
  </ul>
</template>
<script>
export default {
  props: {
    cleaners: {
      type: Array
    },
    domId: {
      type: String
    },
    prefix: {
      type: String
    }
  },
  data() {
    return {

    }
  },
  computed: {
    colorClass() {
      if (this.rejection) {
        return 'red-text'
      }
      return 'blue-text'
    },
    ourDomId() {
      return 'list-' + this.domPrefix + '-' + this.domId
    },
    domPrefix() {
      if (this.rejection) {
        return 'rejected'
      }
      return 'cleaners'
    },
    hasCleaners() {
      return this.cleaners && this.cleaners.length > 0
    },
    rejection() {
      return this.prefix.includes('Rejected')
    }
  }
}
</script>
