<template>
  <div>
    <base-modal classes="background-soft-gray" modal-size="modal-md" dom-id="daysheetModal" title="Day Sheets" :show="show" @close="$emit('close')">
      <div slot="content">
        <spinner v-if="loading" :show="true" message="Loading" :use-modal="false" />
        <div v-if="!loading && show && hasEntries" class="row">
          <div class="col-lg">
            <div class="row">
              <h6>The following cleaners have assignment for {{date}}</h6>
            </div>
            <div class="row">
              <div class="col-sm-2">Tasks</div>
              <div class="col-sm-6">Name</div>
              <div class="col-sm-2">Text</div>
              <div class="col-sm-2">Email</div>
            </div>
            <day-sheets-row v-for="(entry, idx) in entries" :row="entry" :date="date" :key="idx" @update="updateIds"></day-sheets-row>
          </div>
        </div>
        <h6 v-else class="text-center">There are no assigned cleaners</h6>
      </div>
      <div slot="footer">
        <div v-if="show && hasEntries" class="text-center mx-auto">
          <button @click="send" type="button" class="btn btn-default btn-primary" data-dismiss="modal" aria-label="Send">Send</button>
        </div>
      </div>
    </base-modal>
  </div>
</template>
<script>
import api from '@/admins/api'
import BaseModal from '@/modals/base_modal.vue'
import DaySheetsRow from './day_sheets_row.vue'
import Spinner from '@/shared/spinner.vue'
export default {
  props: {
    date: {
      type: String,
      required: true
    },
    serviceAreaIds: {
      type: Array
    },
    show: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BaseModal,
    DaySheetsRow,
    Spinner
  },
  data() {
    return {
      entries: {},
      idsToNotify: {},
      loading: true
    }
  },
  computed: {
    hasEntries() {
      return Object.keys(this.entries).length > 0
    }
  },
  methods: {
    getEntries() {
      this.idsToNotify = {}
      this.loading = true
      api.getDaySheets({ date: this.date, service_area_ids: this.serviceAreaIds }).then(response => {
        this.loading = false
        this.entries = response.data.entries
      })
    },
    updateIds({ jobIds, cleanerId, method, state }) {
      for (var i = 0; i < jobIds.length; i++) {
        if (state) {
          if (this.idsToNotify[jobIds[i]] == null) {
            this.idsToNotify[jobIds[i]] = {}
          }
          this.idsToNotify[jobIds[i]][method] = true
        } else {
          delete this.idsToNotify[jobIds[i]][method]
          if (this.idsToNotify[jobIds[i]] == {})  {
            delete this.idsToNotify[jobIds[i]]
          }
        }
      }
    },
    send() {
      const params = {
        date: this.date,
        job_ids: this.idsToNotify
      }
      this.$emit('send:daysheet', params)
      this.$emit('close')
    }
  },
  watch: {
    date(newValue) {
      if (newValue) {
        this.getEntries()
      }
    },
    serviceAreaId(newValue) {
      this.getEntries()
    },
    show(newValue) {
      if (newValue) {
        this.getEntries()
      }
    }
  }
}
</script>
