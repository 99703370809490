<template>
  <base-modal dom-id="requestModal" :show="show" :title="title" @close="close">
    <template v-if="loading" slot="content">
      <spinner :show="true" :use-modal="false" message="Loading"></spinner>
    </template>
    <template slot="content">
      <component v-bind:is="event.template" v-for="event in shownRequests" :key="event.event_id" :event="event" @do-action="$emit('do-action', $event)" @remove="$emit('remove', $event)" />
      <div class="my-4"></div>
      <div v-show="!loading" class="text-center mx-auto">
        <button type="button" class="btn btn-default btn-primary" @click="close" aria-label="Close">Close</button>
      </div>
    </template>
  </base-modal>
</template>
<script>
import Vue from 'vue'
import api from '@/clients/api'
import { isAdmin, isCleaner } from '@/utils'
import BaseModal from '@/modals/base_modal.vue'
import Spinner from '@/shared/spinner.vue'
import QueueEventCard from '@/shared/events/queue_event_card.vue'
import ForVisitUpdatesTab from '@/shared/events/for_visit_updates_tab.vue'
export default {
  props: {
    requests: {
      type: Object
    },
    show: {
      type: Boolean,
      required: true
    },
    showType: {
      type: String
    }
  },
  components: { BaseModal, Spinner, QueueEventCard, ForVisitUpdatesTab },
  computed: {
    title() {
      return ''
    },
    shownRequests() {
      if (this.requests) {
        return this.requests[this.showType]
      }
    }
  },
  data() {
    return {
      loading: false,
      spinnerMessage: 'Loading',
    }
  },
  mounted() {
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
