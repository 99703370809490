<template>
  <div>
    <round-dropdown :options="statusOptions" @input="$emit('update', $event)" cssClass="''"/>
  </div>
</template>
<script>
export default {
  data() {
    return {
      statusOptions: [{
        value: '',
        label: 'All'
      }, {
        value: 'assigned',
        label: 'Assigned'
      }, {
        value: 'accepted',
        label: 'Accepted'
      }, {
        value: 'in_progress',
        label: 'In Progress'
      }, {
        value: 'completed',
        label: 'Completed'
      }]
    }
  },
  computed: {
  },
}
</script>
