<template>
  <div v-if="properties && (properties.length > 1)" class="calendar-head-top js-property-switcher">
    <div class="full-select display-block w-auto">
      <dropdown :value="locationId" @input="$emit('location-id:change', $event)" :options="properties" index-attr="id" label-attr="address"></dropdown>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import Dropdown from '@/shared/inputs/select.vue'
export default {
  props: ['clientId'],
  components: { Dropdown },
  data() {
    return {
      locationId: null,
      properties: null
    }
  },
  methods: {
    loadProperties() {
      var params = {}
      if (this.clientId) {
         params.client_id = this.clientId
      }
      this.$http.get('/api/properties', {
        params: params
      }).then(response => {
        this.properties = response.data
      })
    }
  },
  watch: {
    clientId(new_value) {
      console.log("client id changed ", new_value, this.clientId)
      if (new_value) {
        this.loadProperties()
      }
    }
  }
}
</script>
